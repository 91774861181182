<template>
    <div>
       
    </div>
</template>

<script>
import { http } from "../../../../../helpers/easyindustriaapi/config";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
    name: 'fornecedoeres',
    data() {
        return {
            fornecedores: []
        }
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
        this.notification.clear;
    },
    mounted() {
        this.get_fornecedores()
    },
    methods: {
        makeToast(variant = null, message = 'Error generic') {
            this.counter++;
            this.$bvToast.toast(message, {
                title: `Notificação`,
                toaster: 'b-toaster-bottom-right',
                variant: variant,
                solid: true,
                appendToast: true
            });
        },
        async get_fornecedores() {
            try {
                //this.loader.get = true;
                let response = await http.get('/pessoa?empresa_id=' + this.currentEmpresa.id)
                    .catch((error) => {
                        console.log(error);
                        this.makeToast('danger', error.response);
                        //this.offLoader();
                    });

                if (response.status === 200) {
                    this.fornecedores = response.data;
                    console.log(this.fornecedores)
                    ///this.loader.get = false;
                    this.gerar_pdf()
                }
            } catch (error) {
                console.log(error);
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }

            }
        },
        gerar_pdf() {
            this.makeToast('sucess', 'Gerando PDF');
            let tamanho = this.fornecedores.length
            const doc = new jsPDF();
            const titulo = "Lista de Fornecedores";
            const header = ["Nome", "Razão", "CNPJ"];
            const footer = ["Total Geral",`${tamanho}`, ""];
            // Adicionar o header e o foote
            doc.text(titulo, 80, 10,);
            // Array para armazenar as linhas da tabela
            const linhasDaTabela = [];
            // Preencher o array com linhas para cada objeto
            this.fornecedores.forEach(function (objeto) {
                const linha = [objeto.pes_apelido, objeto.pes_cidade, objeto.pes_cpfcnpj];
                linhasDaTabela.push(linha);
            });
            doc.autoTable({
                head: [header],
                body: linhasDaTabela,
                foot: [footer],
            });
            // this.clientes.forEach(function (dado) {
            //     doc.autoTable({
            //         body: [dado],
            //     });
            // });
            // Salvar o documento
            doc.save("relatorio.pdf");
        },
    }
}
</script>

<style></style>